import React, { useState, useEffect, useRef } from "react";
import Camera, {FACING_MODES} from "react-html5-camera-photo";
import * as ml5 from "ml5";
import "react-html5-camera-photo/build/css/index.css";

import imageFor from "./David.jpg";

import ImagePreview from "./ImagePreview";

function Analyze(props) {
    const [dataUri, setDataUri] = useState("");
    const [imageClass, setImageClass] = useState("");
    const [confidence, setConfidence] = useState(0);
    const [showCamera, setShowCamera] = useState(true);

    const imgRef = useRef(null);
    const [classifier, setClassifier] = useState();


    // When the model is loaded
    function modelLoaded() {
        console.log('Model Loaded!');
    }

    function toPercent(v) {
        v = v.toString();
        var a = v.split(".");
        v = a[1];
        v = v.substr(0, 4);
        v = (v / 100) + "%";
        return v
    }

    // Make a prediction with a selected image

    useEffect(() => {
        setClassifier(ml5.imageClassifier('./model/model.json', modelLoaded));
        console.log("classifier loaded: ", classifier);
    }, []);


    const analyze1 = () => {
        console.log("analized");
        console.log("classifier: ", classifier);
        const image = document.getElementById('miki');
        // const formatter = Intl.NumberFormat('en-US', {
        //     style: 'percent',
        //     minimumFractionDigits: 2,
        //   })
        // console.log("image: ", image);
        classifier.classify(image, (err, results) => {
            setImageClass(results[0].label);
            setConfidence(toPercent(results[0].confidence));
            console.log("class: ", results);
        });
        setDataUri(null);
    }

    function handleTakePhotoAnimationDone(dataUri) {
        console.log("takePhoto");
        setDataUri(dataUri);
        setShowCamera(false);
    }

    function analyzeAnother() {
        setShowCamera(true);
        setImageClass("");
        setConfidence(0);
        setDataUri(null);
    }




    const isFullscreen = false;
    return (
        <div className="mx-auto max-w-sm min-h-screen h-screen flex flex-col content-center">
            {dataUri ? (
                <div className="flex flex-col">
                    <img src={dataUri} id="miki"></img>
                    <div className="flex flex-row justify-center pt-8">
                        <button onClick={analyze1} className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full drop-shadow-2xl">ANALYZE IMAGE</button>
                    </div>
                    <br />
                    <div className="flex flex-row justify-center pt-8">
                        OR
                    </div>
                    <br />
                    <div className="flex flex-row justify-center pt-8">
                        <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full drop-shadow-2xl" onClick={analyzeAnother}>TAKE A DIFFERENT IMAGE</button>
                    </div>
                </div>
            ) : showCamera && (
                <div className="flex flex-col justify-center">
                    <div className="flex flex-row justify-center text-base text-center pb-8">
                        This is a proof of concept app to determine the malignancy of melanocitic skin lesions.
                        Please consult your doctor about any change in the skin color or structure.
                        This app has not been endorsed by any medical institution.
                    </div>
                    <div className="flex flex-row justify-center text-2xl text-center pb-8">
                        To determine the malignancy of the skin lession allow the camera access, take an image of the lesion and click ANALYZE IMAGE.
                    </div>
                    <Camera
                        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                        isFullscreen={isFullscreen}
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        isImageMirror = {false}
                    />
                </div>
            )}
            {confidence !== 0 && (<div className="flex flex-col content-center justify-center">
                <div className="flex justify-center px-4 pb-4">It seems like your lesion is</div>
                <div className="flex justify-center text-4xl text-blue-500 font-bold pb-4">{imageClass}</div>
                <br />
                <div className="pb-4">Confidence in a correct pre-diagnosis is:</div>
                <div className="flex justify-center pb-">
                    <div className="radial-progress bg-secondary text-primary-content border-4 border-primary after:hidden mb-8" style={{ "--value": { confidence } }}>{confidence}</div>
                </div>
                <br />
                <button className="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full drop-shadow-2xl" onClick={analyzeAnother}>ANALYZE ANOTHER IMAGE</button>
            </div>)}

        </div>
    );
}

export default Analyze;
