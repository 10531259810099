import logo from './logo.svg';
import Analyze from './Analyze';
import Header from './Header';
import Footer from './Footer';

function App() {
  return (
    <div className="min-h-screen flex flex-col content-center">
      <Header />
      <Analyze />
      <Footer />
    </div>
  );
}

export default App;
